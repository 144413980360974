import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Helmet } from "react-helmet";

import { tags } from "./tags";
import { VideoLoop } from "atoms/VideoLoop";
import Template from "../templates/index";
import ArgieGlobalStyles from "fargie/js/components/templates/argie-global-styles";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Container,
  ChakraProvider,
  Flex,
  Heading,
  Img,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useTheme
} from "@chakra-ui/react";

const components = {
  VideoLoop,
  Box,
  Button,
  Link,
  Text,
  GatsbyLink,
  h1: props => <Heading as="h1" mb="10" size="3xl" {...props} />,
  h2: props => <Heading as="h2" mt={20} {...props} />,
  h3: props => <Heading as="h3" size="lg" mt={16} {...props} />,
  p: props => <Text textStyle="paragraph" {...props} />,
  ul: props => <Box textStyle="paragraph" as="ul" ml="8" {...props} />,
  li: props => <Box as="li" mb="8" {...props} />,
  a: props => <Link color="purple.200" {...props} />,
  strong: props => (
    <>
      {" "}
      <Text as="span" textStyle="underline" {...props} />{" "}
    </>
  ),
  blockquote: props => (
    <Box backgroundColor="#CCC" color="black" m={3} mb={7} px={3}>
      <Text textStyle="blockquote" {...props} />
    </Box>
  ),
  ...tags
};

export default function Layout({ children, pageContext }) {
  const { frontmatter } = pageContext;
  const { title } = frontmatter;
  return (
    <MDXProvider components={components}>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <Template>
        <ArgieGlobalStyles />
        <Container my="5">{children}</Container>
      </Template>
    </MDXProvider>
  );
}

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/mdx-layout.tsx";
import { PopularStories } from "components/organisms/popular-stories";
import CreateStoryVideo from "src/videos/EditorCreateStory.mp4";
import AddFieldsVideo from "src/videos/EditorAddFields.mp4";
import EnterMessageTextVideo from "src/videos/EditorEnterMessageText.mp4";
import AddGlobalsVideo from "src/videos/EditorAddGlobals.mp4";
import IfTagVideo from "src/videos/EditorIfTag.mp4";
import AddCommandVideo from "src/videos/EditorAddCommand.mp4";
import NewLocationVideo from "src/videos/EditorNewLocation.mp4";
import TestStoryVideo from "src/videos/EditorTestStory.mp4";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const VideoLoop = makeShortcode("VideoLoop");
const GetGlobalTag = makeShortcode("GetGlobalTag");
const PronounTag = makeShortcode("PronounTag");
const IfTag = makeShortcode("IfTag");
const AddCommandTag = makeShortcode("AddCommandTag");
const Box = makeShortcode("Box");
const GatsbyLink = makeShortcode("GatsbyLink");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`What is the Wrdie Editor?`}</h1>
    <p>{`The Wrdie Editor contains all of the tools you need to write and
publish your interactive story.`}</p>
    <h2>{`Stories`}</h2>
    <VideoLoop width="720px" source={CreateStoryVideo} mdxType="VideoLoop" />
    <p>{`Start by making a story. Any registered user can create a new story and, when you publish your story, anyone can play it.`}</p>
    <h2>{`Fields`}</h2>
    <VideoLoop width="720px" source={AddFieldsVideo} mdxType="VideoLoop" />
    <p>{`Add fields so that your readers can customize their characters.`}</p>
    <h2>{`Messages`}</h2>
    <VideoLoop width="720px" source={EnterMessageTextVideo} mdxType="VideoLoop" />
    <p>{`Next, edit your first message. Messages are one of the primary ways of getting text to the screen. `}</p>
    <p>{`Wrdie stories are made up of multiple messages. When the message runs, the
text and its tags are evaluated on the server and the result is sent to the reader and printed on the screen.`}</p>
    <h2>{`Tags`}</h2>
    <p>{`The little pill things in the toolbar are called Tags. They can be dragged and placed anywhere in your text.`}</p>
    <VideoLoop width="720px" source={AddGlobalsVideo} mdxType="VideoLoop" />
    <p>{`The `}<GetGlobalTag nohover mdxType="GetGlobalTag" />{` tag replaces itself with the data that is saved in a variable and the `}<PronounTag nohover mdxType="PronounTag" />{` tag selects the correctly gendered pronoun based on the reader's preference.`}</p>
    <VideoLoop width="720px" source={IfTagVideo} mdxType="VideoLoop" />
    <p>{`The `}<IfTag nohover mdxType="IfTag" />{` tags only show the text if their condition is true.`}</p>
    <VideoLoop width="720px" source={AddCommandVideo} mdxType="VideoLoop" />
    <p>{`When `}<AddCommandTag nohover mdxType="AddCommandTag" />{` is evaluated, it adds an option for the reader to choose. If the reader selects that option, the specified message runs on the server and the result is sent back to the reader.`}</p>
    <p>{`This is the basic building block of Wrdie stories: messages that add commands, that run messages, that add commands, that run messages, and so on.`}</p>
    <h2>{`Locations`}</h2>
    <p>{`Locations are another way to add interactivity to a story by giving it a sense of place.`}</p>
    <VideoLoop width="720px" source={NewLocationVideo} mdxType="VideoLoop" />
    <p>{`Locations have names, descriptions, commands, and exits. The
reader's character can only be in one location at a time and the
exits allow the reader to move between locations. When a character enters a new location, the description text is run on the server and the result is sent back to the reader.`}</p>
    <h2>{`Testing`}</h2>
    <VideoLoop width="720px" source={TestStoryVideo} mdxType="VideoLoop" />
    <p>{`With a single button click you can jump into any point in your story and begin playing. This makes it easy to test tricky pieces of logic to ensure your readers always get the text they deserve.`}</p>
    <h2>{`What's next?`}</h2>
    <p>{`These are just a few of the things available in the Wrdie editor.
Now that you're familiar with the basics,
head to the `}<a parentName="p" {...{
        "href": "/create"
      }}>{`create page`}</a>{` and start writing your own story.`}</p>
    <Box width="100%" sx={{
      textAlign: "center"
    }} mb={10} mdxType="Box">
  <GatsbyLink to="/discover" mdxType="GatsbyLink">
    <Button colorScheme="purple" rounded="full" mdxType="Button">
      Start writing now
    </Button>
  </GatsbyLink>
    </Box>
    <p>{`Or maybe you'd like to play one first? Here are some of our popular stories:`}</p>
    <PopularStories mdxType="PopularStories" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      